import { DialogModule, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MgButtonComponent } from '@marketguru/core';

@Component({
  selector: 'app-authorized-button-modal',
  templateUrl: './authorized-button-modal.component.html',
  styleUrls: ['./authorized-button-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DialogModule, MgButtonComponent]
})
export class AuthorizedButtonModalComponent {
  constructor(public dialogRef: DialogRef) {}
}
